
import { Route } from 'vue-router/types/router';

export interface IService {
  name: string;
  desc: string;
  img: string;
  show: boolean;
  mapKey: string;
  to?: string | Route;
  service_content: number;
}

interface ILayoutServiceList {
  name: string;
  list: IService[];
}

export const serviceList: ILayoutServiceList[] = [
  {
    name: '自主制作',
    list: [
      {
        name: '课件自助制作',
        desc: '自助制作 .pptx，.doc 格式课件',
        img: require('../assets/service_icons/icon_ppt_v1.png'),
        show: true,
        mapKey: 'editableCourseWare',
        service_content: 0,
      },
      {
        name: '截图版课件自主制作',
        desc: 'pdf文件自助制作截图版 pptx 课件',
        img: require('../assets/service_icons/icon_ppt_v2.png'),
        show: false,
        mapKey: 'screenShotCourseWare',
        service_content: 1,
      },
      {
        name: '内容数字化',
        desc: '图片、方正项目、Word文档转为可编辑内容存入稿件库',
        img: require('../assets/service_icons/icon_src2json.png'),
        show: true,
        mapKey: 'questionBank',
        service_content: 5,
      },
      // {
      //   name: '在线组稿',
      //   desc: '生成方正书版小样、Word等文档',
      //   img: '',
      //   show: true
      // },
      {
        name: 'Word转PPT-自动',
        desc: 'Word转 .pptx 格式文档',
        img: require('../assets/service_icons/icon_word2ppt.png'),
        show: true,
        mapKey: 'wordToPpt_auto',
        service_content: 18
      },
    ]
  },
  {
    name: '格式转换',
    list: [
      {
        name: '图片转Word',
        desc: '图片转 .docx 格式文档',
        img: require('../assets/service_icons/icon_img2word.png'),
        show: true,
        mapKey: 'imgToDocx',
        service_content: 2,
      },
      {
        name: '图片转方正书版',
        desc: '图片转方正书版项目',
        img: require('../assets/service_icons/icon_img2fbd.png'),
        show: false,
        mapKey: 'imgToFbd',
        service_content: 4,
      },
      {
        name: 'Word转方正书版',
        desc: 'Word转方正书版 .fbd 格式文档',
        img: require('../assets/service_icons/icon_word2fbd.png'),
        show: true,
        mapKey: 'wordToFbd',
        service_content: 11
      },
      {
        name: 'Word转方正书版-自动',
        desc: 'Word转方正书版 .fbd 格式文档',
        img: require('../assets/service_icons/icon_word2fbd.png'),
        show: true,
        mapKey: 'wordToFbd_auto',
        service_content: 16
      },
      {
        name: '学用转教用',
        desc: '学用转教用 .fbd 格式文档',
        img: require('../assets/service_icons/icon_fbd2fbd.png'),
        show: true,
        mapKey: 'studentToTeacher',
        service_content: 10
      },
      {
        name: 'PDF转Word',
        desc: 'PDF转 .docx 格式文档',
        img: require('../assets/service_icons/icon_img2word.png'),
        show: true,
        mapKey: 'pdfToDocx',
        service_content: 9,
      },
      {
        name: '方正书版转Word',
        desc: '方正书版项目转 .docx 格式文档',
        img: require('../assets/service_icons/icon_fbd2word.png'),
        show: true,
        mapKey: 'fbdToWord',
        service_content: 3
      }
    ]
  },
  {
    name: '图片处理',
    list: [
      {
        name: '彩色图片改单黑',
        desc: '使用双色和四色文件改为单色稿件',
        img: require('../assets/service_icons/icon_image.png'),
        show: true,
        mapKey: 'colourImageToMonochromeImage',
        service_content: 12,
      },
      {
        name: '单黑图片改专色',
        desc: '图片整体转为特定单色',
        img: require('../assets/service_icons/icon_image.png'),
        show: true,
        mapKey: 'blackImageToMonochromeImage',
        service_content: 13,
      },
      {
        name: '教用辅助线改专色',
        desc: '题图与答图差异的部分改为特定专色',
        img: require('../assets/service_icons/icon_image.png'),
        show: true,
        mapKey: 'teacherImageLineColor',
        service_content: 14,
      },
      {
        name: '图片提高分辨率',
        desc: '调整图片分辨率，进行调清处理',
        img: require('../assets/service_icons/icon_image.png'),
        show: true,
        mapKey: 'imageResolvingPower',
        service_content: 15,
      },
      {
        name: '图片提高清晰度',
        desc: '调整图片清晰度，进行调清处理',
        img: require('../assets/service_icons/icon_img2word.png'),
        show: true,
        mapKey: 'imgClarity',
        service_content: 17,
      },
    ]
  },
  // {
  //   name: '我的内容库',
  //   list: [
  //     // {
  //     //   name: '课件模板库',
  //     //   desc: '课件的模板和样例',
  //     //   img: '',
  //     //   show: true
  //     // },
  //     {
  //       name: '题库&稿件库',
  //       desc: '数字化稿件内容库',
  //       img: require('../assets/service_icons/icon_content_bank.png'),
  //       show: true,
  //       mapKey: 'questionBank',
  //     },
  //   ]
  // },
];
