import api from './api';

/**
 * @description 获取学科信息
 * @param params { academic?:str }
 * @return { Promise }
 */
export const getSubject = (params: { academic?: string }) => {
  return api.get('/api/worksheet/admin/subject/v1/get', params);
};

/**
 * @description 获取学段信息
 * @param params { academic?:str }
 * @return { Promise }
 */
export const getStage = (params: { academic?: string }) => {
  return api.get('/api/worksheet/admin/stage/v1/get', params);
};

/**
 * @description 获取年纪信息
 * @param params { academic?:str }
 * @return { Promise }
 */
export const getGrade = (params: { academic?: string }) => {
  return api.get('/api/worksheet/admin/grade/v1/get', params);
};
